<template>
  <div class="problem-info">
    <div class="form">
      <div class="title" v-if="!id">我要求助</div>
      <van-form ref="vanForm">
        <van-field v-if="depth == 4 && dataForm.buildingId == 0" name="所属社区" label="所属社区" v-model="communityName" placeholder="请选择"
                   readonly clickable is-link @click="communityShow = true"
                   required :rules="[{ required: true, message: '请选择所属社区' }]" />
        <van-popup v-model="communityShow" position="bottom">
          <van-picker show-toolbar value-key="name" :columns="communityList" @confirm="communityConfirm($event)"
                      @cancel="communityShow = false" />
        </van-popup>
        <div class="problem-detail">
          <van-cell required :border="false" class="top">需求描述</van-cell>
          <van-field type="textarea" :readonly="readonly" v-model="dataForm.des" placeholder="请输入" clearable
                     autosize maxlength="100" show-word-limit :rules="[{ required: true, message: '请输入需求描述' }]"></van-field>
        </div>
        <div class="problem-detail">
          <van-cell required :border="false" class="top">求助地址</van-cell>
          <van-field type="textarea" :readonly="readonly" v-model="dataForm.address" placeholder="请输入" clearable
                     show-word-limit :rules="[{ required: true, message: '请输入求助地址' }]"></van-field>
        </div>
        <van-field name="联系人" label="联系人" placeholder="请输入" :readonly="readonly" v-model="dataForm.name" required
                   :rules="[{ required: true, message: '请输入联系人' }]" clearable></van-field>
        <van-field name="联系电话" label="联系电话" placeholder="请输入" :readonly="readonly" v-model="dataForm.mobile" required @blur="changeMobile"
                   :rules="[{ required: true, message: '请输入联系电话' }]" clearable></van-field>
        <van-field name="短信验证码" label="短信验证码" placeholder="请输入" :readonly="readonly" v-model="dataForm.code" required
                   :rules="[{ required: true, message: '请输入短信验证码' }]" v-if="codeShow">
          <template #button>
            <van-button v-if="btnSendText == '发送验证码'" size="small" type="info" @click="getCode" style="border: none">{{btnSendText}}</van-button>
            <div v-else class="btnSendText">{{btnSendText}}</div>
          </template>
        </van-field>
      </van-form>
      <div class="upload-picture" v-if="(fileList && fileList.length) || !id">
        <div class="top">上传图片</div>
        <upload-file
            :fileList="fileList"
            :uploadPath="uploadPath"
            @filesUpload="upload"
            @delFile="delFile"
            :delBtnShow="!readonly"
            :uploadBtnShow="!readonly"
        >
        </upload-file>
      </div>
    </div>
    <div class="button" v-if="!id">
      <van-button class="submit-btn" @click="onSubmit">提交</van-button>
    </div>
  </div>
</template>

<script>
import uploadFile from '../../components/upload/uploadFile'
import {getImageStream} from '@/utils'
export default {
  data() {
    return {
      id: '',
      readonly: false,
      dataForm: {
        orgId: '',
        location: '',
        name: '',
        mobile: '',
        code: '',
        des: '',
        address: '',
        fileMessages: []
      },
      fileList: [],
      uploadPath: 'files/seekHelp/temporary',
      btnSendText: '发送验证码',
      btnSendChecked: false,
      timerCode: null,
      codeShow: true,
      communityName: '',
      communityShow: false,
      communityList: [],
      depth: this.$depth
    }
  },
  components: {
    uploadFile
  },
  created() {
    if (sessionStorage.getItem('userMobile')) {
      this.dataForm.mobile = sessionStorage.getItem('userMobile')
      this.codeShow = false
    }

    this.dataForm.orgId = this.$route.query.orgId
    this.dataForm.address = this.$route.query.address
    this.dataForm.location = this.$route.query.type == 1 ? 2 : this.$route.query.type == 2 ? 3 : this.$route.query.type == 3 ? 4 : 1
    this.dataForm.buildingId = this.$route.query.buildingId
    this.dataForm.houseId = this.$route.query.houseId
  },
  mounted () {
    if (!this.dataForm.address) this.getLocation()
    if (this.depth == 4 && this.dataForm.buildingId == 0) this.getCommunityList()
  },
  methods: {
    getCommunityList () {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/sys/org/communityList`),
        method: 'get',
        params: this.$http.adornParams({
          street: this.$orgId
        })
      }).then(({ data }) => {
        if (data.code == 0) {
          this.communityList = data.communityList
        }
      })
    },
    communityConfirm (e) {
      this.dataForm.orgId = e.id
      this.communityName = e.name
      this.communityShow = false
    },
    changeMobile (value) {
      if (value != sessionStorage.getItem('userMobile')) {
        this.codeShow = true
      } else {
        this.codeShow = false
      }
    },
    getLocation () {
      const T = window.T;
      const geolocation = new T.Geolocation();

      geolocation.getCurrentPosition(e => {
        if (e.lnglat) {
          let geocoder = new T.Geocoder();
          geocoder.getLocation(e.lnglat, (result) => {
            if (result.result)
              this.dataForm.address = result.result.result['formatted_address']
          })
        }
      })
    },
    // 上传图片
    upload(res) {
      this.dataForm.fileMessages = res
    },
    delFile(files) {

    },
    getCode () {
      if (!this.dataForm.mobile) {
        return this.$toast.fail('请输入联系电话')
      } else if (this.btnSendText != '发送验证码') {
        return
      }
      this.$httpCustApp({
        url: 'https://api.hzuht.com/general/verify/sendVerifyCode',
        method: 'post',
        params: this.$httpCustApp.adornParams({
          token: this.dataForm.mobile,
          mobile: this.dataForm.mobile,
          needExist: 1
        })
      }).then(({ data }) => {
        if (data && data.code === 0){
          this.$toast.success ({
            message: '发送成功',
            duration: 1500,
          })
          this.countDown()
          // this.dataForm.code = data.code
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    countDown () {
      let that = this;
      let countDownNum = 60
      this.timerCode = setInterval( () => {
        countDownNum--;
        that.btnSendText = countDownNum
        that.btnSendChecked = true
        if (countDownNum == 0) {
          clearInterval(that.timerCode)
          that.timerCode = null
          that.btnSendText = '发送验证码'
        }
      }, 1000)
    },
    onSubmit() {
      this.$refs.vanForm.validate().then(res => {
        this.$toast.loading({ duration: 0, message: '提交中...', forbidClick: true, });
        // if(!this.dataForm.type) return this.$toast.fail('问题类别不能为空')
        this.$httpCustApp({
          url: this.$httpCustApp.adornUrl('/wxapp/seekHelp/save'),
          method: 'POST',
          data: this.$httpCustApp.adornData({
            orgId: this.dataForm.orgId,
            modelType: this.dataForm.buildingId == 0 ? '' : this.dataForm.location,
            demand: this.dataForm.des,
            newFileMessages: this.dataForm.fileMessages,
            specificPlace: this.dataForm.address,
            createUserName: this.dataForm.name,
            createUserMobile: this.dataForm.mobile,
            verifyCode: this.dataForm.code || sessionStorage.getItem('code'),
            buildingId: this.dataForm.buildingId,
            houseId: this.dataForm.houseId
          })
        }).then(({data}) => {
          this.$toast.clear()
          if(data && data.code === 0) {
            sessionStorage.setItem('userMobile', this.dataForm.mobile)
            sessionStorage.setItem('code', this.dataForm.code)
            this.$toast.success({
              message: '提交成功',
              duration: 2000,
            })
            setTimeout(() => {
              this.$router.go(-1)
            }, 1500);
          }else {
            this.$toast.fail(data.msg)
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .van-cell__value--alone {
  color: #666;
}
.disable {
  pointer-events: none;
}
.problem-info {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.title {
  height: 96px;
  background: #FFFFFF;
  font-size: 32px;
  font-family: 'PingFangSC-Semibold';
  font-weight: 600;
  color: #333333;
  line-height: 96px;
  padding: 0 30px;
}
.problem-item {
  // height: 164px;
  background: #FFFFFF;
  border-radius: 20px 20px 0px 0px;
  // padding: 0 30px;
  .top {
    height: 88px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    .top-left {
      font-size: 34px;
      font-family: 'PingFangSC-Regular';
      font-weight: 400;
      color: #666666;
      span {
        color: #E9564B;
      }
    }
    .top-right {
      width: 28px;
      height: 28px;
    }
  }
  .down {
    // height: 76px;
    font-size: 34px;
    font-family: 'PingFangSC-Medium';
    font-weight: 500;
    color: #333333;
  }
  ::v-deep .van-cell {
    padding: 0 30px;
  }
  ::v-deep .isEmpty textarea {
    height: 50px !important;
  }
}
.problem-detail {
  // height: 254px;
  background: #FFFFFF;
  padding-top: 24px;
  .top {
    font-size: 30px;
    font-family: 'PingFangSC-Regular';
    font-weight: 400;
    color: #666666;
    padding: 0 30px;
    margin-bottom: 12px;
  }
  .des {
    height: 96px;
    font-size: 34px;
    font-family: 'PingFangSC-Regular';
    font-weight: 400;
    color: #333333;
  }
  ::v-deep .van-field__word-limit {
    color: #999999;
  }
  ::v-deep .van-cell {
    padding: 0 30px;
  }
}
.upload-picture {
  // min-height: 270px;
  background-color: #FFFFFF;
  padding: 0 30px 20px;
  ::v-deep .van-cell {
    padding-top: 0;
    padding-left: 0;
  }
  .top {
    height: 88px;
    border-radius: 20px 20px 0px 0px;
    font-size: 26px;
    font-family: 'PingFangSC-Regular';
    font-weight: 400;
    color: #666666;
    line-height: 88px;
  }
  ::v-deep .uploadBox {
    margin: 0;
  }
  ::v-deep .van-uploader__upload {
    background-color: #F5F5F5;
    border-radius: 16px;
  }
}
.button {
  padding: 0 30px;
  height: 88px;
  margin-bottom: 40px;
  .submit-btn {
    width: 100%;
    height: 88px;
    background: #4581F8;
    border-radius: 8px;
    font-size: 32px;
    font-family: 'PingFangSC-Medium';
    font-weight: 500;
    color: #FFFFFF;
    line-height: 32px;
  }
}
::v-deep .van-field__label {
  display: flex;
  align-items: center;
}
.btnSendText {
  color: #1989fa;
  margin-right: 40px;
}
</style>
